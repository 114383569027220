import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AllCategory } from "../../components/AllCategories";
import { ethers } from "ethers";
import Loader from "../../components/Loader/Loader";

export default function Experts() {
  const [spin, setSpin] = useState(true);
  const [expert, setExpert] = useState([]);
  const [category, setCategory] = useState("");
  const categoryInstance: AllCategory = new AllCategory();

  const fetchExperts = async () => {
    const filter = window.CureDappInstance.filters.NewExpert(
      null,
      null,
      null,
      null
    );
    const logs = await window.CureDappInstance.queryFilter(filter);
    const parseLogs = logs.map((log) =>
      window.CureDappInstance.interface.parseLog(log)
    );
    const expertAll = parseLogs.map((ele) => ele.args);
    console.log("All :", expertAll);
    const detailsAll = expertAll;
    setExpert(detailsAll);
    // this.setState({ ...this.state, allSurvey: detailsAll });
    console.log("All :", detailsAll);
  };

  const handleSearch = async (e) => {
    setSpin(true);
    e.preventDefault();
    const val =
      category === "" ? null : ethers.utils.formatBytes32String(category);
    const filter = window.CureDappInstance.filters.NewExpert(
      null,
      null,
      val,
      null
    );
    const logs = await window.CureDappInstance.queryFilter(filter);
    const parseLogs = logs.map((log) =>
      window.CureDappInstance.interface.parseLog(log)
    );
    const expertAll = parseLogs.map((ele) => ele.args);
    console.log("All :", expertAll);
    const detailsAll = expertAll;
    setExpert(detailsAll);
    // this.setState({ ...this.state, allSurvey: detailsAll });
    console.log("All :", detailsAll);
    setSpin(false);
  };

  useEffect(() => {
    (async () => {
      try {
        setSpin(true);
        await fetchExperts();
        setSpin(false);
      } catch {
        // setExist(false);
      }
    })();
  }, []);

  return (
    <div className="container m-5">
      <h1>Doctors </h1>
      <div className="card p-5 ">
        <div className="row">
          <div className="col-lg-8 mx-auto">
            <form className="row g-3 m-3">
              <div className="col-auto">
                <label className="visually-hidden">Specilization</label>
              </div>
              <div className="col-auto">
                <select
                  className="form-control"
                  id="exampleFormControlSelect1"
                  onChange={(event) => setCategory(event.target.value)}
                >
                  {" "}
                  <option value={""} key={0}>
                    --All--
                  </option>
                  {categoryInstance
                    ?.getCategory()
                    ?.map((category: string, index: number) => {
                      return (
                        <option value={index} key={index}>
                          {category}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div className="col-auto">
                <button onClick={handleSearch} className="btn btn-primary mb-3">
                  <i className="fa fa-search"></i> Search{" "}
                </button>
              </div>
            </form>
            <ul className="list-group shadow">
              {spin ? (
                <Loader />
              ) : (
                <>
                  {expert[0] ? (
                    expert.map((ele) => (
                      <li className="list-group-item">
                        <div className="ribbon">
                          <span className="ribbon__content">
                            {ethers.utils.parseBytes32String(ele[2])}
                          </span>
                        </div>
                        <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                          <div className="media-body order-2 order-lg-1">
                            <h5 className="mt-0 font-weight-bold mb-2">
                              {ele[1]}
                            </h5>
                            <p className="font-italic text-muted mb-0 small">
                              {ele[0]}
                            </p>
                            <div className="d-flex align-items-center justify-content-between mt-1">
                              <h6 className="font-weight-bold my-2">
                                <i className="fas-eraswap">ES </i>
                                {ethers.utils.formatEther(
                                  "100000000000000000000"
                                )}
                              </h6>
                            </div>
                          </div>
                          <img
                            src={process.env.PUBLIC_URL + "avtar.jpg"}
                            alt="Generic placeholder"
                            width={200}
                            className="ml-lg-5 order-1 order-lg-2"
                          />
                        </div>
                        {/* <button  className="btn btn-danger mr-3">Consult Now</button> */}
                        <button className="btn btn-warning">
                          <Link to={"/expertdetails/" + ele[0]}>
                            {" "}
                            See Details{" "}
                          </Link>
                        </button>
                      </li>
                    ))
                  ) : (
                    <li className="list-group-item">
                      Sorry We can't find anything
                    </li>
                  )}
                </>
              )}
            </ul>{" "}
            {/* End */}
          </div>
        </div>
      </div>
    </div>
  );
}
