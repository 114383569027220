/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Signer } from "ethers";
import { Provider, TransactionRequest } from "@ethersproject/providers";
import { Contract, ContractFactory, Overrides } from "@ethersproject/contracts";

import type { Chat } from "./Chat";

export class ChatFactory extends ContractFactory {
  constructor(signer?: Signer) {
    super(_abi, _bytecode, signer);
  }

  deploy(overrides?: Overrides): Promise<Chat> {
    return super.deploy(overrides || {}) as Promise<Chat>;
  }
  getDeployTransaction(overrides?: Overrides): TransactionRequest {
    return super.getDeployTransaction(overrides || {});
  }
  attach(address: string): Chat {
    return super.attach(address) as Chat;
  }
  connect(signer: Signer): ChatFactory {
    return super.connect(signer) as ChatFactory;
  }
  static connect(address: string, signerOrProvider: Signer | Provider): Chat {
    return new Contract(address, _abi, signerOrProvider) as Chat;
  }
}

const _abi = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "Sender",
        type: "address",
      },
      {
        indexed: false,
        internalType: "string",
        name: "message",
        type: "string",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "Time",
        type: "uint256",
      },
    ],
    name: "messageSentEvent",
    type: "event",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_expert",
        type: "address",
      },
      {
        internalType: "address",
        name: "_patient",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "_blocknumber",
        type: "uint256",
      },
    ],
    name: "init",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "string",
        name: "message",
        type: "string",
      },
    ],
    name: "sendMessage",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

const _bytecode =
  "0x6080604052600060025534801561001557600080fd5b506102d3806100256000396000f3fe608060405234801561001057600080fd5b50600436106100365760003560e01c8063469c81101461003b57806386863ec614610050575b600080fd5b61004e610049366004610189565b610063565b005b61004e61005e36600461014e565b6100fd565b6001546001600160a01b031633148061008657506000546001600160a01b031633145b6100ab5760405162461bcd60e51b81526004016100a290610278565b60405180910390fd5b6002546100b757436002555b336001600160a01b03167fe8eb8e803227fd36de8ed20710eda7ee26ae6fae0347616be2f86933723b3d4182426040516100f292919061021d565b60405180910390a250565b600080546001600160a01b039485166001600160a01b0319918216179091556001805493909416921691909117909155600255565b80356001600160a01b038116811461014957600080fd5b919050565b600080600060608486031215610162578283fd5b61016b84610132565b925061017960208501610132565b9150604084013590509250925092565b6000602080838503121561019b578182fd5b82356001600160401b03808211156101b1578384fd5b818501915085601f8301126101c4578384fd5b8135818111156101d057fe5b604051601f8201601f19168101850183811182821017156101ed57fe5b6040528181528382018501881015610203578586fd5b818585018683013790810190930193909352509392505050565b6000604082528351806040840152815b8181101561024a576020818701810151606086840101520161022d565b8181111561025b5782606083860101525b50602083019390935250601f91909101601f191601606001919050565b6020808252600b908201526a139bdd08105b1b1bddd95960aa1b60408201526060019056fea2646970667358221220bf43d36e4821c47b4f9a2fac99123f9bff2a288d517d8ff1a6a1a071f4fc1a1f64736f6c63430007050033";
