import React, { useState } from "react";
import { Container, Row, Col } from "reactstrap";
import SolutionItem from "../SolutionItem";

export function Solutions() {
  const [state] = useState({
    imageDetails: [
      {
        imageToRight: false,
        heading: "Your data will travel shouldn't your security",
        contentTextMajor: `CureDapp uses a blockchain to create a user-focused electronic health record and maintain a
                single true version of the user's data.`,
        contentTextMinor: `CureDapp by implementing blockchain will transform the healthcare big data ecosystem by enabling data flow in a secured and structured way, to create patient-centric health information, management, and utilization hub.`,
        alt: "Generic placeholder",
      },
      {
        imageToRight: true,
        heading: "Your data will travel shouldn't your security",
        contentTextMajor: `To make the supply chain less complex and more efficient, CureDapp tracks the complete journey of the supply chain using IOT Devices. IOT tenderly connect all physical objects in the global internet-based infrastructure for the purpose of exchanging information and communication with IOT, CureDApp aims
                to support intelligent identification, location, tracking, monitoring and management.`,
        contentTextMinor: "",
        alt: "Generic placeholder",
      },
    ],
  });
  console.log(state);

  return (
    <React.Fragment>
      <Container>
        <Row>
          <Col>
            <h3 className="text-center">Our Vision</h3>
          </Col>
        </Row>
        <h6 className="text-center">
          Here are some more unique features Era Swap will soon launch
        </h6>
        <br />
        <br />
        <Row>
          <SolutionItem
            image={process.env.PUBLIC_URL + "1.png"}
            alt="Generic placeholder"
            contentText="All Your fitness stats and medical recors in single app"
          />
          <SolutionItem
            image={process.env.PUBLIC_URL + "2.png"}
            alt="Generic placeholder"
            contentText="All Your data stored to Secured & Decentralized network"
          />
          <SolutionItem
            image={process.env.PUBLIC_URL + "3.png"}
            alt="Generic placeholder"
            contentText="Your data are easily accessible by your relevant third parties"
          />
          <SolutionItem
            image={process.env.PUBLIC_URL + "4.png"}
            alt="Generic placeholder"
            contentText="No complication like centralized platforms, using P2P mode"
          />
        </Row>
        <Row>
          <SolutionItem
            image={process.env.PUBLIC_URL + "5.png"}
            alt="Generic placeholder"
            contentText="Connecting Pharmacy, Hospitals, Govt Agencies and You"
          />
          <SolutionItem
            image={process.env.PUBLIC_URL + "6.png"}
            alt="Generic placeholder"
            contentText="Compete on Global Leader-board & Stay Fit, Stay Healthy"
          />
          <SolutionItem
            image={process.env.PUBLIC_URL + "7.png"}
            alt="Generic placeholder"
            contentText="Blood Test by using specialists using special Blood testing devices"
          />
          <SolutionItem
            image={process.env.PUBLIC_URL + "8.png"}
            alt="Generic placeholder"
            contentText="Patients get the total control over their health information"
          />
        </Row>
      </Container>
    </React.Fragment>
  );
}
