import React from "react";
import { Media } from "reactstrap";
import bannerImg from "../../resources/icons/doctor.jpg";
import { Element } from "react-scroll";
import "./Banner.css";

export function Banner() {
  return (
    <Element name="top">
      <header>
        <Media>
          <img src={bannerImg} className="navimg" alt="banner_img" />
        </Media>
      </header>
    </Element>
  );
}
