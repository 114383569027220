import React from "react";
// import logo from "./logo.svg";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { Footer } from "./components/Footer/Footer";
import { Homepage } from "./containers/Homepage";

import "./App.css";

import { NavigationBar } from "./components/NavigationBar";
import { DoctorApplication } from "./components/DoctorApplication";
import Experts from "./containers/Expert/Expert";
import ExpertDetails from "./containers/Expert/ExpertDetails";
import Chat from "./containers/Chat/Chat";
import Myappointments from "./containers/Myappointments";
import History from "./containers/History";

function App() {
  return (
    <div className="App">
      <Router>
        <NavigationBar />
        <br />
        <br />
        <br />

        <Switch>
          <Route exact path="/" component={Homepage} />
          <Route
            exact
            path="/expert-application"
            component={DoctorApplication}
          />
          <Route exact path="/experts" component={Experts} />
          <Route exact path="/chat/:chataddress" component={Chat} />
          <Route
            exact
            path="/expertdetails/:address"
            component={ExpertDetails}
          />
          <Route exact path="/MyAppointments" component={Myappointments} />
          <Route exact path="/history" component={History} />
        </Switch>
      </Router>
      <Footer />
    </div>
  );
}

export default App;
