import React from "react";
// import { Link } from "react-router-dom";
import Banner from "../../components/Banner";
import Vision from "../../components/Vision";
import About from "../../components/About";
import Solutions from "../../components/Solutions";
// import Blog from "../../components/Blog";

export class Homepage extends React.Component {
  render() {
    return (
      <>
        <Banner />
        <div className="App-header">
          <div className="maindiv">
            <Vision /> <br />
            <br />
            <br />
            <About />
            <Solutions />
            {/* <Blog /> */}
          </div>
        </div>
      </>
    );
  }
}
