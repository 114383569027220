/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from "react";
import { sendTransaction } from "../../components/TransactionModal/sendTransaction";
import { AllCategory } from "../AllCategories";
import ReactMarkdown from "react-markdown";
import { ethers } from "ethers";

type Props = {};

type State = {
  next: boolean;
  name: string;
  category: string;
  Details: string;
  fees: string;
  incentive: string;
  categories: string[];
};

export class DoctorApplication extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      next: false,
      category: "",
      name: "Dr. ",
      Details: "",
      fees: "",
      incentive: "",
      categories: [],
    };
  }
  categoryInstance: AllCategory = new AllCategory();

  addExpert = async (e) => {
    const { name, Details, category, fees, incentive } = this.state;
    e.preventDefault();
    await sendTransaction(
      window.CureDappInstance,
      "newExpert",
      [
        Details,
        name,
        ethers.utils.formatBytes32String(category),
        ethers.utils.parseEther(fees),
        (parseFloat(incentive) * 10).toFixed(),
      ],
      "Your are successfully registered as curator"
    );
  };
  async componentDidMount() {
    const categories = await this.categoryInstance?.getCategory();
    this.setState({ categories });
  }

  render() {
    // console.log(this.categoryInstance?.getCategory());

    return (
      <div className="container card m-5">
        <h1> Apply as a Expert </h1>
        <hr />
        <div className="alert alert-primary" role="alert">
          <h4 className="alert-heading">
            Experts has to verify on KYC level 2
          </h4>
          <p>
            For Varification process you must have done KYC level 2 on
            "CURE_DAPP" with Specilization{" "}
          </p>
          <hr />
          <p className="mb-0">
            If you haven't done KYC level 2, Please do it first
          </p>
        </div>
        <div className="row">
          <form className="col-6" onSubmit={(e) => this.addExpert(e)}>
            <div>
              <div className="mb-3">
                <label className="form-label">Name </label>
                <input
                  type="text"
                  className="form-control"
                  value={this.state.name}
                  onChange={(event) =>
                    this.setState({ name: event.target.value })
                  }
                ></input>
              </div>
              <div className="mb-3">
                <label className="form-label">Category</label>
                <select
                  className="form-control"
                  id="exampleFormControlSelect1"
                  onChange={(event) =>
                    this.setState({ category: event.target.value })
                  }
                  required
                >
                  <option value="">-- Select Category --</option>
                  {this.state.categories?.map(
                    (category: string, index: number) => {
                      return (
                        <option value={category} key={index}>
                          {category}
                        </option>
                      );
                    }
                  )}
                </select>
              </div>
              <div className="mb-3">
                <label
                  htmlFor="exampleFormControlTextarea1"
                  className="form-label"
                >
                  Your Description
                </label>
                <textarea
                  className="form-control"
                  rows={10}
                  onChange={(event) =>
                    this.setState({ Details: event.target.value })
                  }
                />
                <div className="form-text text-muted">
                  For Style use{" "}
                  <a
                    className="text-primary"
                    target="_blank"
                    href="https://guides.github.com/pdfs/markdown-cheatsheet-online.pdf"
                  >
                    {" "}
                    MarkDown{" "}
                  </a>{" "}
                  or embed code
                  <a
                    className="text-primary"
                    target="_blank"
                    href="https://stackedit.io/app#"
                  >
                    {" "}
                    Try here{" "}
                  </a>{" "}
                </div>
              </div>
              <div className="mb-3">
                <label className="form-label">Fee </label>
                <input
                  type="number"
                  className="form-control"
                  onChange={(event) =>
                    this.setState({ fees: event.target.value })
                  }
                ></input>
              </div>
              <div className="mb-3">
                <label className="form-label">Announce Incentive in % </label>
                <input
                  type="number"
                  step="0.1"
                  className="form-control"
                  onChange={(event) =>
                    this.setState({ incentive: event.target.value })
                  }
                ></input>
              </div>
            </div>
            <button type="submit" className="btn btn-primary mb-3">
              Confirm identity
            </button>
          </form>
          <div className="col-6">
            <h1>Preview</h1>
            <ReactMarkdown
              skipHtml={false}
              children={this.state.Details}
              className="text-left"
            />
          </div>
        </div>
      </div>
    );
  }
}
