import React from "react";
import { Link } from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavLink,
  NavItem,
  NavbarBrand,
  NavbarToggler,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Nav,
} from "reactstrap";
import logoImage from "../../resources/icons/curelogo.png";
import ConnectWallet from "./ConnectWallet";
// import Image from "../Image";
import "./NavigationBar.css";
import { scroller } from "react-scroll";

const initialState = {
  isOpen: false,
};

export class NavigationBar extends React.Component {
  state = {
    ...initialState,
  };

  toggle = (event) => {
    event.preventDefault();
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  scrollToPosition = (event, element) => {
    event.preventDefault();
    scroller.scrollTo(element, {
      duration: 1500,
      delay: 100,
      smooth: true,
      offset: -99,
    });
  };

  render() {
    return (
      <Navbar light fixed="top" expand="md">
        <NavbarBrand href="/">
          <img src={logoImage} className="header-logo" alt="Era swap logo" />
        </NavbarBrand>
        <NavbarToggler onClick={this.toggle} />
        <Collapse isOpen={this.state.isOpen} navbar>
          <Nav className="mr-auto" navbar>
            <NavItem>
              <NavLink tag={Link} to="/">
                Home
              </NavLink>
            </NavItem>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle className="custom-dropdown-toggle" nav dropdown>
                Our products
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem tag={Link} to="/MyAppointments">
                  My Appointments
                </DropdownItem>
                <DropdownItem tag={Link} to="/experts">
                  Expert's Appointment
                </DropdownItem>
                <DropdownItem tag={Link} to="/tracker">
                  Fitness tracker
                </DropdownItem>
                <DropdownItem tag={Link} to="/pharmacy">
                  Pharmacy
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            <NavItem>
              <NavLink
                tag={Link}
                onClick={(e) => this.scrollToPosition(e, "about_us")}
              >
                About us
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                tag={Link}
                onClick={(e) => this.scrollToPosition(e, "blogs")}
              >
                Blogs
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                tag={Link}
                onClick={(e) => this.scrollToPosition(e, "white_paper")}
              >
                White paper
              </NavLink>
            </NavItem>
          </Nav>
          <Nav pills>
            <NavItem>
              <NavLink
                tag={Link}
                to="/expert-application"
                className="connect-wallet-button"
              >
                Apply as a Expert
              </NavLink>
            </NavItem>
            <NavItem>
              <ConnectWallet />
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    );
  }
}
