import React from "react";
import { Element } from "react-scroll";
import { Container } from "reactstrap";
import ImageDetailButton from "../ImageDetailButton";
import aboutImages from "../../resources/icons/page.jpg";

export function About() {
  return (
    <React.Fragment>
      <Element name="about_us m-5">
        <Container id="about m-5 p-4">
          <h2>About CureDapp</h2>
          <br />
          <ImageDetailButton
            image={process.env.PUBLIC_URL + "About.png"}
            imageClass="mr-3"
            imageRight={false}
            contentText="CureDApp is a Health and Fitness Applications based on Blockchain which will offer multiple utilities to its users. Currently, it offers its users a secure P2P Network to appoint and consult with Doctors nearby them and around the world using CureDApp."
            alt="Generic image"
            headingText="Your medical records secured on blockchain"
          />
          <br />
          <br />
          <br />
          <ImageDetailButton
            image={aboutImages}
            imageClass="mr-3"
            imageRight={true}
            contentText="	Mechanism to tracks the fitness of the users and suggest them to keep a regular routine for their fitness showing the progress and further rewarding in ES for their disciplined approach to staying fit and active in the day to day life."
            alt="Generic image"
            headingText="Your fitness tracker powered on blockchain"
          />
        </Container>
        <br />
        <br />
        <br />
      </Element>
    </React.Fragment>
  );
}
