import { ethers } from "ethers";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import ReactMarkdown from "react-markdown";
import { sendTransaction } from "../../components/TransactionModal/sendTransaction";

export default function Experts() {
  // eslint-disable-next-line no-labels
  const [spin, setSpin] = useState(true);
  const [expert, setExpert] = useState([]);
  const [error, setError] = useState(false);
  const { address } = useParams() as { address: string };
  const handleConsult = async () => {
    await sendTransaction(
      window.CureDappInstance,
      "BookAppointment",
      [address, { value: ethers.utils.parseEther(expert[3]) }],
      "Your appointment has successfully registered "
    );
  };

  useEffect(() => {
    (async () => {
      try {
        setSpin(true);
        const x = await window.CureDappInstance.experts(address);
        setExpert([
          x.name,
          x.Details,
          ethers.utils.parseBytes32String(x.specilization),
          ethers.utils.formatEther(x.fees),
          parseFloat(x.Incentive.toString()) / 10,
        ]);
        // await fetchHost();
      } catch (e) {
        // setExist(false);
        console.log("Error", e);

        setError(true);
      }
      setSpin(false);
    })();
  }, [address]);

  return (
    <div className="container m-5">
      <h1>Doctor Details </h1>
      {spin ? (
        <Loader />
      ) : (
        <>
          {error === true || expert[2] === "" ? (
            <li className="list-group-item">Sorry We can't find anything</li>
          ) : (
            <div className="card p-5 ">
              <div className="ribbon">
                <span className="ribbon__content">{expert[2]}</span>
              </div>
              <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                <div className="media-body order-2 order-lg-1">
                  <h5 className="mt-0 font-weight-bold mb-2">{expert[0]}</h5>
                  <p className="font-italic text-muted mb-0 small">{address}</p>
                  <div className="d-flex align-items-center justify-content-between mt-1">
                    <h3 className="font-weight-bold my-2">
                      <i className="fas-eraswap">ES </i>
                      {expert[3]} {"  "}
                      <div className="badge rounded-pill bg-light p-3">
                        {expert[4]}% Incentive{" "}
                      </div>
                    </h3>
                  </div>
                </div>
                <img
                  src={process.env.PUBLIC_URL + "/avtar.jpg"}
                  alt="Generic placeholder"
                  width={200}
                  className="ml-lg-5 order-1 order-lg-2"
                />
              </div>{" "}
              {/* End */}
              <ReactMarkdown
                skipHtml={false}
                children={expert[1]}
                className="text-left"
              />
              <br />
              <br />
              <br />
              <button className="btn btn-danger mr-3" onClick={handleConsult}>
                Consult Now
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
}
