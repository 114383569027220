import { CustomProvider } from "eraswap-sdk/dist";
// import { ethers } from "ethers";
import { ChatFactory } from "./ChatFactory";
import { CureDappFactory } from "./CureDappFactory";

// const nodeUrl = process.env.REACT_APP_NODE_URL || 'https://node2.testnet.eraswap.network/';

// window.provider = new ethers.providers.JsonRpcProvider(
//   "https://mainnet.eraswap.network"
// );
// window.providerESN = new ethers.providers.JsonRpcProvider(
//   "https://mainnet.eraswap.network"
// );
window.provider = new CustomProvider("mainnet");
window.providerESN = new CustomProvider("mainnet");

window.ChatInstance = ChatFactory.connect(
  "0x9ECE23390e8A636017D4314Aa72F611A641262D1",
  window.providerESN
);
window.CureDappInstance = CureDappFactory.connect(
  "0x9285E73Da59dc68A27D56e3De8e26660c569CF99",
  window.providerESN
);

console.log("custom provider", window.providerESN);
