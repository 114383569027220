import React from "react";
import "./Footer.css";
import { Col, Row, Container } from "reactstrap";

import curelogo from "../../resources/icons/curelogo.png";
import fb from "../../resources/icons/fb.png";
import insta from "../../resources/icons/insta.png";
import twittr from "../../resources/icons/twittr.png";
import linkdin from "../../resources/icons/linkdin.png";
import youtube from "../../resources/icons/utube.png";

export function Footer() {
  return (
    <footer>
      <Container className="">
        <Row>
          <Col className="subscribe">
            <p>Subscribe for Newsletter</p>
            <p>Sign up with email address to receive news and updates</p>
          </Col>
          <Col>
            <div className="footer-input">
              <input
                className="input-field-footer"
                type="text"
                placeholder="Email id"
              />
              <button className="send-btn-footer">Send</button>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <figure className="figure">
              <img
                width={171}
                height={180}
                alt="Cure logo"
                className="figure-img img-fluid"
                src={curelogo}
              />
              <div className="below-footer-txt">
                It is long estaiblished fact that reader can be distracted by a
                readable content
              </div>
            </figure>
          </Col>
          <Col>
            <Row>
              <Col>
                <h2>Menu</h2>
                <ul>
                  <li>About Us</li>
                  <li>Contact Us</li>
                  <li>Privacy policy</li>
                </ul>
              </Col>
              <Col>
                <h2>Home</h2>
                <ul>
                  <li>About Us</li>
                  <li>Contact Us</li>
                  <li>Privacy policy</li>
                </ul>
              </Col>
            </Row>
            <Col>
              <ul className="footer-social-link">
                <li>
                  <img className="social-img" src={fb} alt="fb" />
                </li>
                <li>
                  <img className="social-img" src={insta} alt="insta" />
                </li>
                <li>
                  <img className="social-img" src={twittr} alt="twitter" />
                </li>
                <li>
                  <img className="social-img" src={linkdin} alt="linkdin" />
                </li>
                <li>
                  <img className="social-img" src={youtube} alt="youtube" />
                </li>
              </ul>
            </Col>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}
