import { ethers } from "ethers";
import { addresses } from "eraswap-sdk";
import { KycDappFactory } from "eraswap-sdk/dist/typechain/ESN";

const config = addresses["production"];
export class AllCategory {
  getCategory = () => {
    return this.categoryArray;
  };
  getIndex = (value: string) => {
    return this.categoryArray.indexOf(value) + 1;
  };
  getValue = (value: number) => {
    return this.categoryArray[value - 1];
  };

  fetchSpecilization = async () => {
    console.log("fetching...");

    const KYCInst = KycDappFactory.connect(config.ESN.kycdapp, window.provider);
    const filter = KYCInst.filters.KycFeeUpdated(
      2,
      ethers.utils.formatBytes32String("CURE_DAPP"),
      null,
      null
    );
    const logs = await KYCInst.queryFilter(filter);
    const parseLogs = logs.map((log) => KYCInst.interface.parseLog(log));
    const specilizations = parseLogs.map((ele) =>
      ethers.utils.parseBytes32String(ele.args[2])
    );
    const List = parseLogs.map((ele) => [
      ethers.utils.parseBytes32String(ele.args[2]),
      ethers.utils.formatEther(ele.args[3].toString()),
    ]);
    console.log("Val", List);

    return specilizations;
  };

  categoryArray = [
    "Cardiologist",
    "Dentist",
    "Quack Doctor",
    "Allergist",
    "Aayurvedic",
    "Allergist",
    "Anesthesiologist",
    "Ayurvedic Medicine",
    "Ayurvedic Surgery",
    "Ballet Dance Trainer",
    "Ballroom Dance Trainer",
    "Bharatanatyam Dance Trainer",
    "Bio Electromagnetic Therapy",
    "Biofield Therapy",
    "Bone Marrow Transplant",
    "Boot Camp Instructors",
    "Cardiologist",
    "Chiropractic Medicine",
    "Christian Faith Healing",
    "Colon and Rectal Surgeon",
    "Colorpuncture Therapy",
    "Contemporary Dance Trainer",
    "Critical Care Specialist",
    "Dentist",
    "Dermatologist",
    "Dietician",
    "Emergency Medicine",
    "Endocrinologist",
    "Family Physician",
    "Folk Dance Trainer",
    "Gastroenterologist",
    "Gatka Master",
    "General Surgeon",
    "Geriatric Medicine",
    "Gym Trainer",
    "Hematologist",
    "Herbal Medicine",
    "Hip Hop Dance Trainer",
    "Homeopathic Medicine",
    "Homeopathic Surgery",
    "Homeopathy",
    "Hospice and Palliative",
    "Immunologist",
    "Indian Wrestling Master",
    "Infectious Disease Specialist",
    "Internist",
    "Irish Dance Trainer",
    "Jazz Dance Trainer",
    "Jiu Jitsu Master",
    "Kalaripayattu Master",
    "Karate Master",
    "Kathak Dance Trainer",
    "Kathakali Dance Trainer",
    "Krav Maga Master",
    "Kuchipudi Dance Trainer",
    "Kung Fu Master",
    "Kuttu Varisai Master",
    "Lathi Master",
    "Lifestyle  Trainers",
    "Light Therapy",
    "Magnetic Therapy",
    "Manipuri Dance Trainer",
    "Medical Devices",
    "Medical Gastroenterology",
    "Medical Genetics",
    "Medical Laboratory",
    "Modern Dance Trainer",
    "Muay Thai Master",
    "Naturopathy and Yoga Science",
    "Nephrologist",
    "Neurologist",
    "Ninjutsu Master",
    "Nursing",
    "Obstetrician and Gynecologist",
    "Occupational Therapy",
    "Odissi Dance Trainer",
    "Oncologist",
    "Ophthalmologist",
    "Osteopathic",
    "Others therapist",
    "Otolaryngologist",
    "Pathologist",
    "Pediatrician",
    "Performance Personal Trainers",
    "Pharmacy",
    "Physiatrist",
    "Physical Trainer",
    "Physiotherapy",
    "Physique Trainers",
    "Plastic Surgeon",
    "Podiatrist",
    "Preventive Medicine",
    "Psychiatrist",
    "Pulmonologist",
    "Quack Doctor",
    "Radiologist",
    "Reiki",
    "Rheumatologist",
    "Sarit Sarak Master",
    "Silambam Master",
    "Sleep Medicine Specialist",
    "Sports Equipment",
    "Sports Medicine Specialist",
    "Surgical Gastroenterology",
    "Swing Dance Trainer",
    "Taekwondo Master",
    "Tap Dance Trainer",
    "Thang-ta Master",
    "Traditional Chinese Medicine",
    "Unani Medicine",
    "Urologist",
    "Veterinary",
    "Water Aerobics",
    "Yoga Trainer",
    "Test",
  ];
}
