import React from "react";
import ImageDetail from "../ImageDetail";
import "./Vision.css";
import { Container } from "reactstrap";

export function Vision() {
  return (
    <React.Fragment>
      <Container>
        <h1 className="m-3">FEATURES</h1>
        <p></p>
        <ImageDetail
          image={process.env.PUBLIC_URL + "F1.jpg"}
          imageClass="mr-3"
          imageRight={false}
          contentText="1.	CureDApp offers P2P Appointment with listed Doctors on the application"
        />
        <ImageDetail
          image={process.env.PUBLIC_URL + "F3.jpg"}
          width="100px"
          imageClass="mr-3"
          imageRight={true}
          contentText="2.	Connect with your appointed Doctors securely on Era Swap powered blockchain network."
          alt="Generic placeholder"
        />
        <ImageDetail
          image={process.env.PUBLIC_URL + "1.jpg"}
          imageClass="mr-3"
          imageRight={false}
          contentText="3.	List Your nearby doctors and consult with them without extra middlemen charges"
          alt="Generic placeholder"
        />
      </Container>
    </React.Fragment>
  );
}
