import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Status = ["Pending", "Progressed", "Cured", "Refunded"];

export default function Myappointments() {
  // const [spin, setSpin] = useState(true);

  const [iswallet, setIswallet] = useState(false);
  const [appointments, setAppointments] = useState([]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (window.wallet) {
        setIswallet(true);
        clearInterval(intervalId);
      } else setIswallet(false);
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const fetchAppointments = async () => {
    const Address = window?.wallet.address
      ? window.wallet.address
      : await window.wallet.getAddress();
    const filter = window.CureDappInstance.filters.AppointmentBooked(
      Address,
      null,
      null
    );
    const logs = await window.CureDappInstance.queryFilter(filter);
    const parseLogs = logs.map((log) =>
      window.CureDappInstance.interface.parseLog(log)
    );
    const expertAll = parseLogs.map((ele) => ele.args);
    console.log("All :", expertAll);
    const detailsAll = await Promise.all(
      expertAll.map(async (ele) => {
        const appointment = await window.CureDappInstance.appointments(ele[2]);
        return appointment;
      })
    );
    // setExpert(detailsAll);
    setAppointments(detailsAll.reverse());
    // this.setState({ ...this.state, allSurvey: detailsAll });
    console.log("All :", detailsAll);
  };

  useEffect(() => {
    (async () => {
      try {
        await fetchAppointments();
      } catch {
        // setExist(false);
      }
    })();
  }, [iswallet]);

  return (
    <>
      {!iswallet ? (
        <div className=" mx-auto">Please Connect to wallet</div>
      ) : (
        <div className="container m-5">
          <h1> My Appointments </h1>
          <div className="card p-5 ">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th scope="col">Patient</th>
                  <th scope="col">Status</th>
                  <th scope="col">Handle</th>
                </tr>
              </thead>
              <tbody>
                {appointments[0]
                  ? appointments.map((ele) => (
                      <tr>
                        <td> {ele[1]} </td>
                        <td>{Status[ele[4]]}</td>
                        <td>
                          <Link to={"/chat/" + ele[3]}>See Here</Link>
                        </td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
}
