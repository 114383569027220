/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./Chat.css";

import { useParams } from "react-router-dom";
import { ChatFactory } from "../../ethereum/ChatFactory";
import { sendTransaction } from "../../components/TransactionModal/sendTransaction";

const Receiver = (props: { message: string; time: any }) => (
  <div className="chat-message-right pb-4">
    <div>
      <img
        src="https://bootdey.com/img/Content/avatar/avatar1.png"
        className="rounded-circle mr-1"
        alt="Chris Wood"
        width={40}
        height={40}
      />
    </div>
    <div className="callout left flex-shrink-1 bg-light rounded py-2 px-3 mr-3">
      <div className="font-weight-bold mb-1"></div>
      {props.message}
      <div className="text-muted text-right small text-nowrap mt-2">
        {timeDifference(props.time)}
      </div>
    </div>
  </div>
);

const Sender = (props: { message: string; time: any }) => (
  <div className="chat-message-left pb-4">
    <div>
      <img
        src="https://bootdey.com/img/Content/avatar/avatar3.png"
        className="rounded-circle mr-1"
        alt="Sharon Lessman"
        width={40}
        height={40}
      />
    </div>
    <div className="callout right flex-shrink-1 bg-light rounded py-2 px-3 ml-3">
      <div className="font-weight-bold mb-1"></div> {/* name */}
      {props.message}
      <div className="text-muted text-right small text-nowrap mt-2">
        {timeDifference(props.time)}
      </div>
    </div>
  </div>
);

const Status = ["Pending", "Progressed", "Cured", "Refunded"];

function timeDifference(previous) {
  var msPerMinute = 60 * 1000;
  var msPerHour = msPerMinute * 60;
  var msPerDay = msPerHour * 24;
  var msPerMonth = msPerDay * 30;
  var msPerYear = msPerDay * 365;

  var current = Date.now();

  var elapsed = current - previous * 1000;

  if (elapsed < msPerMinute) {
    return Math.round(elapsed / 1000) + " seconds ago";
  } else if (elapsed < msPerHour) {
    return Math.round(elapsed / msPerMinute) + " minutes ago";
  } else if (elapsed < msPerDay) {
    return Math.round(elapsed / msPerHour) + " hours ago";
  } else if (elapsed < msPerMonth) {
    return "approximately " + Math.round(elapsed / msPerDay) + " days ago";
  } else if (elapsed < msPerYear) {
    return "approximately " + Math.round(elapsed / msPerMonth) + " months ago";
  } else {
    return "approximately " + Math.round(elapsed / msPerYear) + " years ago";
  }
}

export default function Chat() {
  const { chataddress } = useParams() as { chataddress: string };
  const [iswallet, setIswallet] = useState(false);
  const [appointment, setAppointment] = useState([]);
  const [chat, setChat] = useState([]);
  const [isExpert, setIsExpert] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  const [address, setAddress] = useState("");

  const fetchAppointments = async () => {
    if (!window.wallet) {
      setError(true);
      return;
    }
    const Address = window?.wallet.address
      ? window.wallet.address
      : await window.wallet.getAddress();
    console.log(chataddress);

    // Appointment
    const x = await window.CureDappInstance.appointments(chataddress);
    await setAppointment([...appointment, x.expert, x.patient, x.status]);
    console.log(x);

    window.ChatInstance = ChatFactory.connect(chataddress, window.providerESN);

    const filter = window.ChatInstance.filters.messageSentEvent(
      null,
      null,
      null
    );
    const logs = await window.ChatInstance.queryFilter(filter);
    const parseLogs = logs.map((log) =>
      window.ChatInstance.interface.parseLog(log)
    );
    const chatAll = parseLogs.map((ele) => ele.args);
    console.log("All :", chatAll);
    setChat(chatAll);

    if (Address === x.expert) {
      setIsExpert(true);
    } else if (Address !== x.patient) setError(true);
  };

  useEffect(() => {
    const intervalId = setInterval(async () => {
      if (window.wallet) {
        const Address = window?.wallet.address
          ? window.wallet.address
          : await window.wallet.getAddress();
        setIswallet(true);
        setAddress(Address);
        clearInterval(intervalId);
      } else setIswallet(false);
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    const intervalId = setInterval(async () => {
      if (window.wallet) {
        const Address = window?.wallet.address
          ? window.wallet.address
          : await window.wallet.getAddress();
        setIswallet(true);
        setAddress(Address);
        clearInterval(intervalId);
      } else setIswallet(false);
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    // let intervalId2;
    (async () => {
      try {
        await fetchAppointments();
        setError(false);
        console.log("App", appointment);

        // intervalId2 = setInterval(async () => {
        //   const currentBlockNumber = await window.provider.getBlockNumber();
        //   const filter = window.ChatInstance.filters.messageSentEvent(null, null,null);
        //   const logs = await window.ChatInstance.queryFilter(filter,currentBlockNumber - 1,'latest');
        //   const parseLogs = logs.map((log) =>
        //     window.ChatInstance.interface.parseLog(log)
        //   );
        //   const chatDiff = parseLogs.map((ele) => ele.args);
        //   // console.log("All :", chatAll);
        //   setChat([...chat, ...chatDiff]);

        // }, 5000);
      } catch (e) {
        // setExist(false);
        console.log(e);

        setError(true);
      }
    })();

    console.log("Error : ", error);
    console.log(iswallet);

    // return () => {
    //   // clearInterval(intervalId2);
    // };
  }, [iswallet]);

  //send message
  const sendMessage = async () => {
    await sendTransaction(
      window.ChatInstance,
      "sendMessage",
      [message],
      "Your appointment has successfully registered "
    );
  };

  const Proceed = async () => {
    await sendTransaction(
      window.CureDappInstance,
      "ProceedAppointment",
      [chataddress],
      "Your appointment has successfully proceed "
    );
  };

  const Cured = async () => {
    await sendTransaction(
      window.CureDappInstance,
      "CureAppointment",
      [chataddress],
      "Your appointment has successfully Cured "
    );
  };

  const Refund = async () => {
    await sendTransaction(
      window.CureDappInstance,
      "Refund",
      [chataddress],
      "Your appointment has successfully Cancelled. You'll get refund soon "
    );
  };

  return (
    <main className="content m-3">
      {!iswallet || error ? (
        <div>Something went wrong or you have not access for it </div>
      ) : (
        <div className="container p-0">
          <h1 className="h3 mb-3">Appointment Window</h1>
          <div className="card">
            <div className="row g-0">
              <div className="col-12 col-lg-7 col-xl-12">
                <div className="py-2 px-4 border-bottom d-none d-lg-block">
                  <div className="d-flex align-items-center py-1">
                    <div className="position-relative">
                      <img
                        src="https://bootdey.com/img/Content/avatar/avatar3.png"
                        className="rounded-circle mr-1"
                        alt="Sharon Lessman"
                        width={40}
                        height={40}
                      />
                    </div>
                    <div className="flex-grow-1 pl-3">
                      <strong>{!isExpert ? "Expert" : "Paitent"} </strong>
                      <div className="text-muted small">
                        <em>Status : {Status[appointment[2]]}</em>
                      </div>
                    </div>
                    <div>
                      {isExpert ? (
                        <>
                          <button
                            className="btn btn-primary btn-lg mr-1 px-3"
                            onClick={Proceed}
                          >
                            Proceed
                          </button>
                          <button
                            className="btn btn-info btn-lg mr-1 px-3 d-none d-md-inline-block"
                            onClick={Cured}
                          >
                            Cured
                          </button>
                        </>
                      ) : null}
                      <button
                        className="btn btn-light border btn-lg px-3"
                        onClick={Refund}
                      >
                        Refund
                      </button>
                    </div>
                  </div>
                </div>
                <div className="position-relative">
                  <div className="chat-messages p-4">
                    {/* <p>No more here ...</p> */}
                    {chat.map((ele) => {
                      if (ele[0] === address)
                        return (
                          <Receiver time={ele[2].toNumber()} message={ele[1]} />
                        );
                      else
                        return (
                          <Sender time={ele[2].toNumber()} message={ele[1]} />
                        );
                    })}

                    {/* */}
                  </div>
                </div>
                <div className="flex-grow-0 py-3 px-4 border-top">
                  <div className="input-group">
                    <button
                      className="btn btn-light"
                      onClick={() =>
                        window.open(
                          "https://eraswap.cloud/Host/0x7E3E5B0A5035a3706b9BC717d69997b14dDf0453",
                          "",
                          "width=1001,height=650"
                        )
                      }
                    >
                      <i className="fa fa-paperclip"></i>{" "}
                    </button>
                    <input
                      type="text"
                      className="form-control"
                      onChange={(e) => setMessage(e.target.value)}
                      placeholder="Type your message"
                    />
                    <button onClick={sendMessage} className="btn btn-primary">
                      <i className="fa fa-send"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </main>
  );
}
